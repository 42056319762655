.header-root {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 700px;
  align-items: center;
}

.header-logo {
  font-weight: 700;
  font-size: 24px;
}
