.dashboard-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-width: 700px;
}

.dashboard-title {
  font-weight: 700;
  font-size: 2em;
}

.dashboard-panel {
  margin-top: 2em;
}

.dashboard-message {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  padding-bottom: 3em;
  padding-top: 3em;
  color: var(--primary-color);
}

.dashboard-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

.dashboard-form-title {
  font-weight: 700;
  font-size: 2em;
  margin-bottom: 1em;
}

.dashboard-form-subtitle {
  font-weight: 700;
  font-size: 1.25em;
  margin-bottom: 1em;
  margin-top: 1em;
}

.dashboard-form-error {
  color: #e60023;
  margin-bottom: 0.5em;
}
.dashboard-form-button-container {
  display: flex;
  width: 100%;
  margin-top: 1.25em;
}

.dashboard-form-button {
  flex: 1;
}

.dashboard-form-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}

.dashboard-form-secondary-text {
  display: flex;
  margin-bottom: 1em;
  align-items: center;
  color: rgb(150, 150, 150);
}

.dashboard-form-input {
  margin-bottom: 1em;
}

.dashboard-link-list {
  margin-top: 1em;
  max-height: 500px;
  overflow: scroll;
}

.dashboard-link-list-header:hover {
  box-shadow: none;
}

.img-thumbnail {
  width: 40px;
  height: 40px;
}

.link-cell:hover {
  text-decoration: underline;
}
