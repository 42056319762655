.footer-root {
  display: flex;
  justify-content: space-evenly;
  max-width: 700px;
  align-items: center;
  border: 1px solid rgb(200, 200, 200);
  margin-top: 2em;
  margin-bottom: 5em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;

  /* background-color: blue; */
}
