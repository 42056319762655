/* style.css */

/* Reset default margin and padding */
body,
figure,
h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

/* Set page width and center content */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.landing-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

/* Style navigation bar */
nav {
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  width: 100%;
}

.nav-logo {
  font-size: 28px;
  font-weight: 700;
  margin: 1em;
  color: #fff;
}

/* Style header section */
header {
  flex: 2;
  width: 100%;
  background-color: var(--primary-color);
  padding-top: 5rem;
  /* padding-bottom: 3rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-video {
  width: 50%;
  height: auto;
  margin-bottom: 5rem;
}

.header-content {
  max-width: 1200px;
  background-color: transparent;
  text-align: center;
}

header h1 {
  font-size: 56px;
  margin-bottom: 20px;
  color: #fff;
}

header img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 20px;
}

header h2 {
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
}

header button {
  background-color: var(--primary-color);
  color: #fff;
  padding: 1em 2em;
  font-size: 18px;
  cursor: pointer;
  margin-top: 2em;
  border: 1px solid;
  transition: 0.3s;
  margin-bottom: 5em;
}

header button:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

/* Style card section */

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 5rem;
}

.section-content {
  display: flex;
  max-width: 1200px;
  background-color: transparent;
}

.section-title {
  padding-bottom: 5rem;
  font-size: 42px;
  font-weight: 700;
  color: var(--primary-color);
}

.card {
  width: 100%;
  max-width: 600px;
  margin-right: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary-color);
}

.card img {
  max-width: 100%;
  width: 100px;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 50px;
}

.card h3 {
  text-align: center;
  margin-top: 10px;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}

.card p {
  text-align: center;
  margin-top: 5px;
  font-size: 18px;
  font-size: 600;
  line-height: 30px;
}

/* Style footer */
footer {
  /* background-color: var(--primary-color); */
  border-top: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 5em;
  padding-bottom: 5em;
}

.footer-content {
  background-color: transparent;
  max-width: 1200px;
  width: 100%;
  display: flex;
}

.footer-column {
  flex: 1;
  background-color: transparent;
  margin-right: 1em;
}

.footer-column h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.footer-column p {
  font-size: 16px;
  line-height: 30px;
}

.footer-column a {
  font-size: 16px;
  line-height: 30px;
  color: var(--primary-color);
}

/* Media queries for mobile devices */
@media (max-width: 768px) {
  nav {
    padding: 0px;
  }

  .section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1em;
    margin-right: 1em;
  }

  header {
    /* padding-bottom: 5em; */
  }

  header img {
    width: 150px;
    height: 150px;
  }

  .header-video {
    width: 90%;
  }

  header h1 {
    font-size: 46px;
  }

  header h2 {
    font-size: 18px;
  }

  section {
    display: flex;
    flex-direction: column;
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .card {
    max-width: 300px;
    margin-right: 0;
    margin-bottom: 5em;
    padding: 0px;
  }

  .card img {
    margin-bottom: 20px;
  }

  footer {
    display: flex;
    flex-direction: column;
    padding: 0px;
    padding-top: 3em;
    padding-bottom: 2em;
    text-align: center;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
  }

  .footer-column {
    background-color: transparent;
    margin: 0px;
    margin-bottom: 2em;
  }
}
